.mapboxgl-ctrl-zoom-in {
  @apply h-5 w-5 #{!important};
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-plus' width='14' height='24' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpath d='M12 5l0 14'%3E%3C/path%3E%3Cpath d='M5 12l14 0'%3E%3C/path%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl-zoom-out {
  @apply h-5 w-5 #{!important};
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-minus' width='14' height='20' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpath d='M5 12l14 0'%3E%3C/path%3E%3C/svg%3E") !important;
}

/* stylelint-disable */
.mapbox-gl-draw_polygon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-pencil-plus' width='24' height='24' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpath d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4'%3E%3C/path%3E%3Cpath d='M13.5 6.5l4 4'%3E%3C/path%3E%3Cpath d='M16 19h6'%3E%3C/path%3E%3Cpath d='M19 16v6'%3E%3C/path%3E%3C/svg%3E") !important;
  padding: 1rem !important;
}
/* stylelint-enable */

/* stylelint-disable */
.mapboxgl-ctrl-group {
  box-shadow: 0px 3px 24px 0px rgb(0 0 0 / 25%) !important;
}
/* stylelint-enable */

.mapboxgl-canvas {
  @apply h-full w-full #{!important};
}
