// Modal Related
.mantine-Modal-title {
  @apply text-2xl font-bold;
}

.mantine-ActionIcon-hover:hover {
  @apply bg-primary text-white;
}

// Checkbox Related
.mantine-Checkbox-label {
  @apply mb-0;
}

.mantine-Checkbox-input {
  @apply cursor-pointer;
}

// RadioGroup Related
.mantine-Radio-label {
  @apply mb-0;
  @apply cursor-pointer;
}

.mantine-Radio-inners {
  @apply cursor-pointer;
}

// Select Related
.mantine-Select-selected {
  @apply bg-primary/50;
  @apply text-black;
}

.mantine-Select-hovered {
  @apply bg-primary/50;
  @apply text-black;
}

.mantine-Select-wrapper {
  @apply h-full;
}

.mantine-Select-input {
  @apply h-full;
}

// Tabs Related
.mantine-Tabs-tab {
  @apply min-h-[40px];
  @apply p-2;
  transition: background-color 0.2s;

  &[data-active] {
    @apply bg-primary/50;
  }

  &:hover {
    @apply bg-primary/50;
  }
}

.mantine-Tabs-tabLabel {
  @apply text-black;
}

.mantine-Tabs-tabsList {
  @apply flex items-center justify-center md:justify-start;
}

.mantine-Tooltip-tooltip {
  @apply bg-grey-900 px-5 py-3 text-xs font-normal leading-5;
}

.mantine-Tooltip-arrow {
  @apply bg-grey-900;
}

.mantine-Table-root {
  &[data-striped] tbody tr:nth-of-type(odd) {
    @apply bg-accent-grey bg-opacity-80 #{!important};
  }

  tbody tr[class*="hover"]:hover {
    @apply bg-accent-grey bg-opacity-100 #{!important};
  }
}

/* stylelint-disable */
.mantine-Table-root tbody tr td {
  padding: 8px 24px !important;
}
/* stylelint-enable */

/* stylelint-disable-next-line */
.mantine-DatePicker-monthPickerControlActive {
  @apply bg-turquoise-600 text-white;
}

.mantine-DatePicker-yearPickerControlActive {
  @apply bg-turquoise-600 text-white;
}

.mantine-Switch-input {
  @apply h-full w-full;
}
