@tailwind base;
@tailwind components;
@tailwind utilities;

@import "app";

:root {
  --toastify-color-info: #2a67f6;
  --toastify-color-success: #10d1bb;
  --toastify-color-warning: #f8ec5b;
  --toastify-color-error: #ed3f55;
  --toastify-toast-width: 30rem;
}

.Toastify__toast-icon {
  @apply m-0 h-7 w-7;
}

.Toastify__close-button {
  @apply p-1;
}

::selection {
  background-color: rgb(75 228 194/ 50%);
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(#000, 0);
}

* {
  box-sizing: inherit;
}

@layer base {
  html {
    font-family: "Gotham", sans-serif;
    @apply min-h-screen overflow-y-scroll bg-accent-lighterGrey;
  }

  body {
    @apply overflow-x-hidden overflow-y-hidden #{!important};
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    @apply bg-transparent;
  }

  button:focus-visible {
    @apply focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-2;
  }
}

@layer components {
  .custom-select {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-selector" width="18" height="18" viewBox="0 0 24 24" stroke-width="1" stroke="grey" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 9l4 -4l4 4" /><path d="M16 15l-4 4l-4 -4" /></svg>')
      no-repeat 95% 50%;
    appearance: none;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}
